































import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {BuroBilgiEntity} from "@/entity/BuroBilgiEntity";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import AdresForm from "@/components/comps/forms/AdresForm.vue";

@Component({
    components: {
        AdresForm,
        VergiNumarasi
    }
})
export default class BuroBilgiForm extends Mixins(ObjectInputMixin) {

};
