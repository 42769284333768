













































import {Component, Vue, Watch} from "vue-property-decorator";
import {BuroListResponseProvider} from "@/services/BuroService";
import {BuroBilgiEntity} from "@/entity/BuroBilgiEntity";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import BuroBilgiForm from "@/components/buro/detay/BuroBilgiForm.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";
import AdresForm from "@/components/comps/forms/AdresForm.vue";
import BankaBilgileriForm from "@/components/comps/forms/BankaBilgileriForm.vue";


@Component({
    components: {BankaBilgileriForm, AdresForm, VergiNumarasi, FormDialogButton, DeleteButton, BuroBilgiForm}
})
export default class BuroListe extends Vue {

    postData = {
        buro: {},
        adres: {},
    };

    input() {
        console.log("on parent postData : ", this.postData);
    }

    @Watch('postData')
    onPostDataChange() {
        console.log("post data changed to", this.postData);
    }

    search: string = "";
    data: BuroBilgiEntity[] | null = null;
    items: Array<object> = [];
    headers = [
        {
            text: "Ad",
            align: "start",
            sortable: true,
            value: "ad"
        },
        {text: "İl", value: "il"},
        {text: "Vergi Dairesi", value: "vergiDairesi"},
        {text: "Email", value: "email"},
        {text: "İşlemler", value: "actions", sortable: false}
    ];

    mounted() {
        window.setTimeout(() => {
            this.data = BuroListResponseProvider().data;
            let items: Array<object> = [];
            this.data.forEach((buro: BuroBilgiEntity) => {
                let item: any = {};
                item.id = buro.id;
                item.ad = buro.ad;
                item.il = buro.adresler.find(adres => adres.birincil)?.ilce?.il?.isim;
                item.vergiDairesi = buro.vergiDairesi;
                item.email = buro.iletisimBilgileri.find(ib => ib.tur == 'eposta')?.deger;
                items.push(item);
            })
            this.items = items;
            console.log(this.items);
        }, 500)
    }

    add() {
        this.$router.push('/buro/ekle');
    }

    /*  displayAddBuro() {
          this.dialog = true;
      }*/

    editBuro(item: BuroBilgiEntity) {
        this.$router.push('/buro/' + item.id?.toString() + '/duzenle');
    }

    deleteBuro(item: BuroBilgiEntity) {
        // emin misin!
    }

    detay(item: BuroBilgiEntity) {
        this.$router.push('/buro/' + item.id?.toString() + '/detay');
    }


}
